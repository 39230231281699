@import 'index/footer.scss';
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600&display=swap');

html, body{
  margin: 0;
  padding: 0;
  background-color: $smoke;
  font-family: 'Montserrat', sans-serif;
}

b{
  font-weight: 500;
}

#cookieBanner {
  max-width: 320px;
  font-size: 13px;
  padding: 0px 15px;

  position: fixed;
  bottom: 8px;
  left: 2vw;
  align-items: center;

  display: flex;

  color: white;
  background-color: rgba(0, 0, 0, 0.3);
  background-image: linear-gradient(120deg, rgba(43, 153, 255, 0.7), rgba(0, 45, 88, 1));

  a {
    color: #76acdf;
    text-decoration: none;
  }

  div {
    background-color: $primary;
    cursor: pointer;
    padding: 8px;
    margin-left: 13px;
  }
}

.row{
  display: flex;
}

.col{
  display: flex;
  flex-direction: column;
}

.wrap{
  flex-wrap: wrap;
}

.btn{
  cursor: pointer;
  text-decoration: none;
  color: inherit;
  padding: 5px 20px;
  width: fit-content;
  width: -moz-fit-content;
  display: block;

  text-transform: uppercase;
  font-size: 10pt;

  transition: 100ms all ease-in-out;

  &:hover{
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4);
  }
}

.noneLink{
  color: unset;
  text-decoration: none;
}

#menuBtn {
  height: 30px;
  margin-right: 20px;
  display: none;
  cursor: pointer;

  @include medium {
    display: block;
  }
}

.links{
  flex-grow: 1;
  margin-left: 40px;
  max-width: 50%;

  display: flex;
  justify-content: space-between;

  @include medium{
    z-index: 12;
    display: none;
    box-sizing: border-box;
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    max-width: unset !important;
    flex-direction: column;
    backdrop-filter: blur(5px);
    padding: 20px;
    background-color: rgba(0, 0, 0, 0.3);
    background-image: linear-gradient(120deg, rgba(43, 153, 255, 0.5), rgba(0, 45, 88, 0.5));
  }

  .link{
    cursor: pointer;
    color: white;
    text-decoration: none;
    
    @include medium{
      padding: 8px 0;
      margin: 2px 0;
    }

    &.close{
      display: none;

      @include medium {
        display: block;
      }
    }

    .anim{
      width: 50%;
      margin: 0 auto;
      opacity: 0;
      height: 1px;
      background-color: white;
      transition: 100ms all cubic-bezier(0.445, 0.05, 0.55, 0.95);
    }

    img {
      height: 20px !important;
      transform: translateY(4px);
      margin-right: -5px;
    }

    &:hover .anim{
      opacity: 0.8;
      width: 100%;
      margin-bottom: 0%;
    }
  }

  .more{
    position: relative;

    .moreMenu{
      display: none;
      position: absolute;
      // top: 20px;
      background-color: rgba(255, 255, 255, 0.7);
      box-shadow: $boxShadowSoft;
      width: 200%;

      // transition-duration: 1000ms;
      // transition-property: all;
      // opacity: 0;

      a{
        color: $primary;
        padding: 2px 10px;
        border-bottom: 1px solid $primary;
        text-decoration: none;
        display: block;

        &:hover{
          background-color: rgba(255, 255, 255, 1);
        }
      }
    }
  }

  .more:hover .moreMenu, .moreMenu:hover{
    display: block;
  }
}



.svgTitle{
  margin: 0 auto;
  width: fit-content;
  width: -moz-fit-content;
  position: relative;
  padding: 20px 100px 30px;
  color: $primary;

  @include small{
    padding: 20px 10% 30px;
  }


  .text{
    font-size: $titleSize;

    @include small{
      font-size: $titleSize - 3pt;
    }

    position: relative;
    z-index: 2;
  }

  .svg{
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;
    height: 100%;

    polygon, path{
      fill: $smoke;
    }

    svg{
      width: 100%;
      height: 100%;
    }
  }
}