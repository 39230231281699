@import "../vars.scss";

#footer {
  // background-color: $primary;
  background-image: linear-gradient(120deg, #2B99FF, #002e58);
  color: white;
  padding: 10px 10vw;
  font-size: 11pt;

  justify-content: space-between;
  align-items: center;

  .twitter,
  .blog {
    margin-right: 40px;
  }

  .centerRow{
    align-items: center;
  }

  .twitter,
  .linkedin,
  .blog {
    min-width: fit-content;
    min-width: -moz-fit-content;
    color: white;
    text-decoration: none;
    align-items: center;

    img {
      height: 16px;
      margin-right: 7px;
    }
  }

  // .blog {
  //   img {
  //     height: 100%;
  //     width: 18px;
  //   }
  // }

  .others {
    a {
      color: white;
      margin-left: 20px;
      text-decoration: none;

      &:first-of-type {
        margin-left: auto;
      }
    }
  }

  @include small {
    a {
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }
}
