$primary: #0E5BA3;
$dark: #454545;
$grey: #7A7A7A;
$smoke: #e9ecf1;
$boxShadowSoft: 0px 0px 10px rgba(0, 0, 0, 0.2);

$titleSize: 22pt;

$medium: screen and (max-width: 1270px);

@mixin hideOnMedium{
  @media screen and (max-width: 1270px) {
    display: none;
  }
}

@mixin medium{
  @media screen and (max-width: 1270px) {
    @content;
  }
}

@mixin hideOnSmall{
  @media screen and (max-width: 1070px) {
    display: none;
  }
}

@mixin small{
  @media screen and (max-width: 1070px) {
    @content;
  }
}